import { Cookies } from 'react-cookie';


export const cookies = new Cookies();

export const expireTime = new Date(Date.now() + 60 * 60 * 1000)




